<div class="content">
    <div class="main-content row">
        <div class="info col-xs-12 col-md-6">
            <h2>Hola, <br> Soy <span>Claudio Mldo</span>, <br> Desarrollador full Stack</h2>
            <div class="desc row">
                <div class="col-xs-12 col-md-6">
                    <p>Desarrollo web y móvil a tu medida</p>
                </div>
                <div class="col-xs-12 col-md-6" *ngIf="profile">
                    <a [href]="profile.cv" target="_blanck" class="button hvr-float">Descargar CV
                        <i class="lni lni-download"></i></a>
                </div>

            </div>
        </div>
        <div class="image col-xs-0 col-md-6">
            <img src="assets/svg/undraw_solution_mindset_34bi.svg" alt="developer svg">
        </div>
    </div>

    <img class="Group-1951" src="assets/svg/Group 1951.svg" alt="developer svg">
    <img class="Group-1953" src="assets/svg/Group 1953.svg" alt="developer svg">

    <div class="bio">
        <ng-container *ngIf="profile; else profileTemplate">
            <app-aboutme [profile]="profile"></app-aboutme>
        </ng-container>
        <ng-template #profileTemplate>
            <app-loader></app-loader>
        </ng-template>
    </div>

    <div class="content-clients">
        <app-clients *ngIf="clients" [clients]="clients"></app-clients>
    </div>

    <div class="content-services main-content">
       <app-services></app-services>
    </div>

    
</div>


<!-- 
<img src="assets/svg/blackball.svg" alt="developer svg">
<img src="assets/svg/Group 1949.svg" alt="developer svg">

<img src="assets/svg/Group 1953.svg" alt="developer svg">
<img src="assets/svg/Group 1956.svg" alt="developer svg">
<img src="assets/svg/Rectangle 565.svg" alt="developer svg">
<img src="assets/svg/Rectangle 5651.svg" alt="developer svg">

-->