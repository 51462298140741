
<footer>
    <div class="icon_footer">
        <i class="lni lni-chevron-up"></i>
    </div>

    <!--
        <div class="sponsors">
        <app-clients-footer></app-clients-footer>
    </div>
    -->
    <div class="social">
        <a href="https://www.instagram.com/claudio.mldo" target="_BLANK"><i class="lni lni-instagram-original"></i></a>
        <a href="https://github.com/MClaudio" target="_BLANK"><i class="lni lni-github-original"></i></a>
        <a href="https://www.linkedin.com/in/claudiomldo/" target="_BLANK"><i class="lni lni-linkedin-original"></i></a>
    </div>
    <p class="credits">&copy; 2021 Todos los derechos reservados | Designed By <span>Claudio Mldo</span></p>
</footer>
