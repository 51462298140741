import { Component, OnInit } from '@angular/core';
import { Work } from '../../models/work';
import { FireService } from '../../services/fire.service';
import { Category } from '../../models/Category';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss'],
})
export class WorksComponent implements OnInit {
  public categories: Category[] = new Array();

  constructor(private fireService: FireService) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    await this.fireService.getCategories().subscribe(async (res: any) => {
      this.categories = res as Category[];

      // let allCategories = new Category();
      // allCategories.name = 'Todo';
      // allCategories.works = [];

    
        this.categories.map(async (category: Category) => {
          await this.fireService.getWorks(category.uid).subscribe((res) => {
            //console.log("category", res)
            // res.forEach((work:Work) => {
            //   //console.log(allCategories.works.includes(work));
            //   if(!allCategories.works.includes(work)){
            //     allCategories.works.push(work);
            //   }
            // });
            //allCategories.works = allCategories.works.concat(res) as Work[];
            category.works = res as Work[];
            //console.log(allCategories);
          });
        });

      
      //this.categories.push(allCategories);
      this.categories.reverse();
    });
    //console.log(this.categories)
  }
}
