import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public menu:boolean = false;
  

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(evt =>{
      //console.log("route", evt)
      if(evt) this.menu = false;
    })
  }

  
}
