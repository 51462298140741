<div class="content">
    <i class="exit-modal lni lni-close" (click)="exitModal()"></i>

    <div class="head">
        <h2>{{work.client}}</h2>
        <p class="short-desc">{{work.shortDescription}}</p>
    </div>
    <div class="body">
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="desc">
                    
                    <p class="short-desc">{{work.description}}</p>
                    <ng-container *ngIf="work.technologies">
                        <h2>Que se uso</h2>
                        <div class="technologies" *ngFor="let technologi of work.technologies">
                            <div class="detail">
                                <i class="lni lni-chevron-right"></i>
                                <div class="tech-desc">
                                    <img [src]="technologi.img" alt="">
                                    <p><span>{{technologi.name}}</span></p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div class="side">
                    <h2 class="title">Detalles</h2>
                    <div class="detail">
                        <i class="lni lni-chevron-right"></i>
                        <p><span>Cliente</span> {{work.client}}</p>
                    </div>
                    <div class="detail">
                        <i class="lni lni-chevron-right"></i>
                        <p><span>Tareas</span> {{work.tasks}}</p>
                    </div>
                    <div class="detail" *ngIf="work.url">
                        <i class="lni lni-chevron-right"></i>
                        <p><span>Website</span> <a [href]="work.url" target="_blanck">{{work.url}}</a></p>
                    </div>
                    <div class="detail" *ngIf="work.github">
                        <i class="lni lni-chevron-right"></i>
                        <p><span>Codigo</span> <a [href]="work.github" target="_blanck">{{work.github}}</a></p>
                    </div>
                    <div class="detail" *ngIf="work.buy">
                        <i class="lni lni-chevron-right"></i>
                        <p><span>Comprar</span> <a [href]="work.buy" target="_blanck">{{work.buy}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>