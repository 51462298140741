import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FireService } from '../../services/fire.service';
import { SnackBarComponent } from '../../components/snack-bar/snack-bar.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public submitted = false;
  public durationInSeconds = 5;
  public msjForm: UntypedFormGroup;
  private inputs:any;

  constructor(
    private fb: UntypedFormBuilder,
    private fireService: FireService,
    private snackBar: MatSnackBar
  ) {
    this.msjForm = this.createForm();
    //console.log(this.msjForm)
  }

  ngOnInit(): void {}

  createForm() {
    return this.fb.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
        ],
      ],
      phone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      msj: ['', Validators.required],
    });
  }

  get form() {
    //console.log(this.msjForm.controls)
    this.inputs = document.getElementsByTagName('mat-form-field');
    return this.msjForm.controls;
  }

  onSubmit(formDirective: FormGroupDirective) {

    if (this.msjForm.invalid) {
      return;
    }

    this.submitted = true;
    let msj = this.msjForm.value;

    this.fireService
      .setContact(msj)
      .then((result) => {
        
        this.submitted = false;
        this.openSnackBar();
        formDirective.resetForm();
        this.msjForm.reset();

      })
      .catch((err) => {
        console.log('Error', err);
        this.submitted = false;
      });
    
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

}
