<ng-container *ngIf="works; else elseTemplate">
    <div class="content">

        <div class="row">
            <div class="col col-xs-12 col-sm-6 col-md-4 col-lg-4" *ngFor="let work of works">
                <div class="box" [defaultImage]="defaultImagBg" [lazyLoad]="work.img">
                    <div class="bg">
                        <h2>{{work.client}}</h2>
                        <p>{{work.shortDescription}}</p>
                        <div class="butons">
                            <a *ngIf="work.url" [href]="work.url" target="_blanck"><i class="lni lni-link"></i></a>
                            <!--<a (click)="openDialog(work)"><i class="lni lni-search-alt"></i></a>-->
                            <a (click)="openWork(work)"><i class="lni lni-eye"></i></a>
                        </div>
                        <div class="technologies">
                            <img [defaultImage]="defaultImg" [lazyLoad]="technologi.img" [alt]="technologi.name" *ngFor="let technologi of work.technologies"> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    
    <div class="loader">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>

</ng-template>


