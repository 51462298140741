<div class="content">
    
    <ng-container *ngIf="work; else elseTemplate">
        <div class="row">
            <div class="col-bg col-xs-12 col-md-8">
                <img [defaultImage]="defaultImag" [lazyLoad]="work.img" [alt]="work.client">
            </div>

            <div class="col-desc col-xs-12 col-md-4">
                
                <h2>{{work.client}}</h2>
                <h4 class="short-desc">{{work.shortDescription}}</h4>
                <p class="desc">{{work.description}}</p>
                <h4>Detalles del trabajo:</h4>
                <div class="detail">
                    <i class="lni lni-chevron-right"></i>
                    <p><span>Cliente:</span> {{work.client}}</p>
                </div>
                <div class="detail">
                    <i class="lni lni-chevron-right"></i>
                    <p><span>Tareas:</span> {{work.tasks}}</p>
                </div>
                <div class="detail" *ngIf="work.url">
                    <i class="lni lni-chevron-right"></i>
                    <p><span>Website:</span> <a [href]="work.url" target="_blanck">{{work.url}}</a></p>
                </div>
                <div class="detail" *ngIf="work.github">
                    <i class="lni lni-chevron-right"></i>
                    <p><span>Código:</span> <a [href]="work.github" target="_blanck">{{work.github}}</a></p>
                </div>
                <div class="detail" *ngIf="work.buy">
                    <i class="lni lni-chevron-right"></i>
                    <p><span>Comprar:</span> <a [href]="work.buy" target="_blanck">{{work.buy}}</a></p>
                </div>

                <ng-container *ngIf="work.technologies">
                    <h4>Que se uso</h4>
                    <div class="technologies" *ngFor="let technologi of work.technologies">
                        <div class="detail">
                            <i class="lni lni-chevron-right"></i>
                            <div class="tech-desc">
                                <img [defaultImage]="defaultImg" [lazyLoad]="technologi.img">
                                <p><span>{{technologi.name}}</span></p>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="close">
                    <a routerLink="/works"><i class="lni lni-arrow-left"></i></a>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-template #elseTemplate>
        <app-loader></app-loader>
    </ng-template>
</div>