import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FireService {

  constructor(private firestore: AngularFirestore) { }

  getProfile(){
    return this.firestore.collection('perfil').doc('9KIBo6HjEKLHYh6dQNQG').valueChanges();
  }
  getClients(){
    return this.firestore.collection('clients').valueChanges();
  }
  getCategories(){
    return this.firestore.collection('works').valueChanges();
  }

  getWorks(uidCategory:string){
    return this.firestore.collection('works').doc(uidCategory).collection('works').valueChanges();
  }

  setContact(contact:any){
    return this.firestore.collection('contacts').doc().set(Object.assign({}, contact))
  }

  getWorkById(uid:string){
    //console.log("idConsult", uid)
    //return this.firestore.collectionGroup('works', res => res.where('uid','==', uid)).valueChanges();
    return this.firestore.collectionGroup('works', res => res.where('uid','==', uid)).valueChanges();
  }

  

}
