import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorksComponent } from './works/works.component';
import { ContactComponent } from './contact/contact.component';
import { ComponentsModule } from '../components/components.module';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { WorkComponent } from './work/work.component';
import { AppRoutingModule } from '../app-routing.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    HomeComponent, 
    WorksComponent, 
    ContactComponent, 
    WorkComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MaterialModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LazyLoadImageModule
  ], 
  exports:[
    HomeComponent,
    WorksComponent, 
    ContactComponent,
    WorkComponent
  ]
})
export class SlidesModule { }
