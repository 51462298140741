import { Component, OnInit } from '@angular/core';
import { FireService } from '../../services/fire.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public downloadFile = false;
  public profile:any;
  public clients:any;

  constructor(private fireService:FireService) { }

  ngOnInit(): void {
    this.getData();
  }

  async getData(){
    await this.fireService.getProfile().subscribe(res =>{
      //console.log('res', res)
      this.profile =  res;
    });

    await this.fireService.getClients().subscribe(res=>{
      //console.log(res)
      this.clients = res;
      
    })
 
  }

}
