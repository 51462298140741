<div class="content main-content">
    <div class="row" style="width: 100%;">
        <div class="form-content col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h2>Solo dime hola !</h2>
            <p>O cuéntame sobre tus ideas.</p>
            <form [formGroup]="msjForm" #formDirective="ngForm" novalidate (ngSubmit)="onSubmit(formDirective)">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline" >
                            <mat-label>Nombre</mat-label>
                            <input formControlName="name" matInput placeholder="Tu Nombre">
                            <mat-hint *ngIf="form.name.invalid && form.name.touched">El nombre no es valido</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline" >
                            <mat-label>Apellido</mat-label>
                            <input formControlName="lastName" matInput placeholder="Tu Apellido">

                            <mat-hint *ngIf="form.lastName.invalid && form.lastName.touched">El apellido no valido
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline" >
                            <mat-label>Correo</mat-label>
                            <input type="email" autocomplete="email" formControlName="email"  matInput placeholder="Tu Correo">

                            <mat-hint *ngIf="form.email.invalid && form.email.touched">El correo no valido</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline" >
                            <mat-label>Teléfono</mat-label>
                            <input type="tel" formControlName="phone" matInput placeholder="Tu Teléfono o Celular">

                            <mat-hint *ngIf="form.phone.invalid && form.phone.touched">El teléfono no valido</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <mat-form-field appearance="outline" >
                            <mat-label>Mensaje</mat-label>
                            <textarea formControlName="msj"  matInput placeholder="Escríbeme tu mensaje"></textarea>

                            <mat-hint *ngIf="form.msj.invalid && form.msj.touched">El mensaje es requerido</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="btn col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <button type="submit" mat-raised-button color="primary" [disabled]="submitted">
                            <span>Enviar</span>
                            <mat-spinner *ngIf="submitted" class="custom-spinner" diameter="20" color="a"></mat-spinner>
                        </button>
                    </div>
                </div>


            </form>
        </div>
        <div class="side-sgnature col-xs-12 col-sm-6 col-md-6 col-lg-6" style="display: flex; justify-content: center;">
            <div class="box">
                <p class="sgnature">ClaudioMldo</p>
                <div class="social">
                    <a href="https://www.instagram.com/claudio.mldo" target="_BLANK"><i class="lni lni-instagram-original"></i></a>
                    <a href="https://github.com/MClaudio" target="_BLANK"><i class="lni lni-github-original"></i></a>
                    <a href="https://www.linkedin.com/in/claudiomldo/" target="_BLANK"><i class="lni lni-linkedin-original"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

