
    <!-- <div class="section-block">
      <d-sticky [view]="stickyView" [container]="demoDocViewerMain" [zIndex]="1000" (statusChange)="log($event)">
          <div class="sticky-content">This is the content of sticky. You can set content here.</div>
      </d-sticky>
  </div> -->

    <app-header></app-header>

    <div class="content">
      <router-outlet></router-outlet>
    </div>


    <app-footer></app-footer>
