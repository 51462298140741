import { Component, Input, OnInit } from '@angular/core';
import { Category } from '../../models/Category';
import { Work } from '../../models/work';


@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  @Input() categories: Category[];

  constructor() { 
    
  }

  ngOnInit(): void {

  }


}
