import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public curretUri: string = '/';

  constructor(private router: Router) {
    router.events.subscribe((res: any) => {
      if (res.url !== undefined) {
        this.curretUri = res.url;
      }
    });
  }

  ngOnInit(): void {}

  onRouterTo(url: string) {
    this.router.navigate([url]);
  }
}
