import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  stickyView = {
    top: 100,
    bottom: 0
  };
  demoDocViewerMain:any;

  ngOnInit(): void {
    this.demoDocViewerMain = document.querySelector('.doc-viewer-container .main');
  }

  log(...v:any) {
    //console.log(...v);
  }


}
