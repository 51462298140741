<div class="content">
    <div class="row">
        <div class="col col-xs-12 col-md-4">
            <div class="card">
                <i class="lni lni-code-alt"></i>
                <h3>Desarrollo BackEnd</h3>
                <p>Desarrollo en lenguajes de programación Java, JavaScript y Python</p>
                <span>01</span>

            </div>
        </div>
        <div class="col col-xs-12 col-md-4">
            <div class="card">
                <i class="lni lni-display-alt"></i>
                <h3>Desarrollo FrontEnd</h3>
                <p>Desarrollo en HTML, CSS y JavaScript, frameworks Angular y React</p>
                <span>02</span>
            </div>
        </div>
        <div class="col col-xs-12 col-md-4">
            <div class="card">
                <i class="lni lni-mobile"></i>
                <h3>Desarrollo Móvil </h3>
                <p>Desarrollo multiplataforma con Flutter</p>
                <span>03</span>
            </div>
        </div>
    </div>
</div>