
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkDialogComponent } from '../work-dialog/work-dialog.component';
import { Work } from '../../models/work';
import { Router } from '@angular/router';



@Component({
  selector: 'app-works-list',
  templateUrl: './works-list.component.html',
  styleUrls: ['./works-list.component.scss'],
})
export class WorksListComponent implements OnInit {
  @Input() works:Work[];

  public defaultImg = 'assets/img/Eclipse-1s-200px.gif'
  public defaultImagBg = 'assets/img/loader.gif'
  constructor(public dialog: MatDialog, private router:Router) {}

  ngOnInit(): void {

  }

  openDialog(data:any) {
    this.dialog.open(WorkDialogComponent, {
      data
    });
  }

  openWork(work:Work){
    //console.log(work)
    this.router.navigate(['works/'+work.uid])
  }
}
