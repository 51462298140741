<div class="main-content content">
    <h2>Mis Trabajos</h2>
    <div class="portfolio">
        <ng-container *ngIf="categories.length>0; else elseTemplate">
            <app-portfolio [categories]="categories"></app-portfolio>
        </ng-container>
        <ng-template #elseTemplate>
            <app-loader></app-loader>
        </ng-template>
    </div>

</div>