

<app-menu [ngClass]="{'open-menu': menu == true, 'clouse-menu': menu == false}"></app-menu>
<div class="row">
    <div class="col-xs-4 col-md-2 col">
        <a routerLink="/"><span class="signature">ClaudioMldo</span></a>
    </div>
    <div class="col-xs-4 col-md-8 col">

    </div>
    <div class="col-xs-4 col-md-2 col">
        <div class="menu-toggler" [ngClass]="{'open': menu}" (click)="menu=!menu">
            <div class="bar half start"></div>
            <div class="bar"></div>
            <div class="bar half end"></div>
        </div>
    </div>
</div>

