import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutme',
  templateUrl: './aboutme.component.html',
  styleUrls: ['./aboutme.component.scss']
})
export class AboutmeComponent implements OnInit {

  @Input() profile:any;

  public defaultImg = 'assets/img/loader.gif'

  constructor() { }

  ngOnInit(): void {
  }

}
