import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Work } from '../../models/work';
import { FireService } from '../../services/fire.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  public defaultImag = 'assets/img/loader.gif'
  public defaultImg = 'assets/img/Eclipse-1s-200px.gif'
  public work:Work;
  private idWork:string;

  constructor(private route:ActivatedRoute, private fireService:FireService) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData(){
    await this.route.params.subscribe(params => {
      this.idWork = params['id'];
      });

      await this.fireService.getWorkById(this.idWork).subscribe((res)=>{
        this.work = res[0] as Work;
        //console.log("res", res)
      })
    
    
      //console.log("work", this.idWork);
  }

}
