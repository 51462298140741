<div class="content">
    
    <div class="weav weav-top" style="height: 150px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none"
        style="height: 100%; width: 100%;">
        <path d="M-3.15,120.69 C503.05,0.30 -2.59,121.67 503.61,0.30 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill: #151A30;"></path>
    </svg></div>

    <h2>Quienes confian en mi</h2>
    
    <div class="content-slide">
        <!--swiper-button-prev-->
        <div class="icon arrow swiper-button-prev">
            <i class="lni lni-angle-double-left"></i>
        </div>


        <div class="swiper-container" [swiper]="config" [(index)]="index">
            <div class="swiper-wrapper">
                <a [href]="client.url" target="blanck" class="swiper-slide" *ngFor="let client of clients"  >
                    <div class="slide-content">
                        <img [defaultImage]="defaultImg" [lazyLoad]="client.img" [alt]="client.name">
                    </div>
                </a>
            </div>
        </div>

        <!--swiper-button-next-->
        <div class="icon arrow swiper-button-next">
            <i class="lni lni-angle-double-right"></i>
        </div>
    </div>


    <div class="weav weav-bott" style="height: 150px; overflow: hidden;"><svg viewBox="0 0 500 150" preserveAspectRatio="none"
        style="height: 100%; width: 100%;">
        <path d="M-3.15,120.69 C503.05,0.30 -2.59,121.67 503.61,0.30 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill: #151A30;"></path>
    </svg></div>
</div>