import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { LoaderComponent } from './loader/loader.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ClientsComponent } from './clients/clients.component';
import { AppRoutingModule } from '../app-routing.module';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { MaterialModule } from '../material/material.module';
import { WorksListComponent } from './works-list/works-list.component';
import { WorkDialogComponent } from './work-dialog/work-dialog.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { ServicesComponent } from './services/services.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LoaderComponent,
    ClientsComponent,
    PortfolioComponent,
    AboutmeComponent,
    WorksListComponent,
    WorkDialogComponent,
    SnackBarComponent,
    ServicesComponent,
  ],
  imports: [
    CommonModule, 
    SwiperModule, 
    AppRoutingModule, 
    MaterialModule,
    LazyLoadImageModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    PortfolioComponent,
    ClientsComponent,
    AboutmeComponent,
    SnackBarComponent,
    ServicesComponent,
  ],
})
export class ComponentsModule {}
